.center-list-grid {
	align-items: start;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 400px 1fr;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 15px;
}

.center-list-nav-wrapper {
	display: block;
	position: relative;
	width: 100%;
	overflow-y: auto;
}

.center-list-nav-wrapper:hover ::-webkit-scrollbar {
	background: #bdbdbd;
}

/* .center-item {
	align-items: center;
	display: flex;
	position: relative;
	gap: 15px;
	width: 100%;
	border-left: 4px solid transparent;
	cursor: pointer;
	transition: all .3s ease-in-out;
	padding: 10px;
	margin-bottom: 5px;
} */

.center-item {
	border-left: 4px solid transparent;
	cursor: pointer;
	gap: 15px;
	margin-bottom: 5px;
	padding: 10px;
	position: relative;
	transition: all .3s ease-in-out;
	width: 100%;
}

.center-item:hover {
	border-color: #c1cce0;
}

.center-item:last-child {
	margin-bottom: 0;
}

.center-item.active {
	background-color: #fff;
	border-color: var(--color-theme);
	box-shadow: 0px 2px 6px 1px rgb(0 0 0 / 8%);
}

.center-item.active .center-item-title {
	color: var(--color-theme);
}

.center-item-logo {
	height: 90px;
	width: 90px;
	object-fit: cover;
	border-radius: 12px;
	border: 1px solid #f8f8f8;
}

.center-item-title {
	margin-bottom: 5px;
	font-size: 1.1rem;
	font-weight: 400;
}

.center-item-subtitle {
	margin-bottom: 0;
	font-size: .9rem;
}


.center-services,
.center-data-basic-details {
	padding: 10px 15px;
}

.center-list-preview {
	background-color: #fff;
	position: relative;
	display: none;
}

.search-found-dialog,
.no-search-found {
	background-color: #fff;
	text-align: center;
	display: block;
	padding: 140px 0;
	margin: 0 auto;
	width: 100%;
}

.search-found-dialog>img,
.no-search-found>img {
	max-width: 260px;
	margin-bottom: 15px;
}

.no-search-found-content,
.search-found-dialog-content {
	max-width: 500px;
	margin: 0 auto;
	padding: 0 15px;
}

.center-services {
	background-color: #f8f8f8;
}

.center-list-preview-close-bar {
	text-align: center;
	display: none;
	align-items: center;
	justify-content: center;
	color: #e63939;
	background-color: #ffcaca;
}

.iframe-map {
	border: 0 none;
	height: 300px;
	width: 100%;
}

.center-preview-services {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: start;
	justify-content: space-between;
	grid-gap: 10px;
}

.center-preview-services>p {
	margin-bottom: 0;
}

.center-data-preview-footer {
	padding: 15px;
}

.center-list-preview-close-bar>svg {
	font-size: 24px;
	line-height: 1;
	stroke-width: 3;
}

.result-page-search {
	margin-bottom: 30px;
}

.center-list-preview.active {
	display: block;
	height: auto;
	width: 100%;
	z-index: 1;
}

.center-list-nav-wrapper,
.center-data-preview {
	height: auto;
	overflow: auto;
	max-height: 642px;
	padding-bottom: 5px;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
	width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
	background: #f8f8f8;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #d8d8d8;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #bdbdbd;
	cursor: grab;
}

.data-preview-overlay {
	display: none;
}

@media screen and (max-width: 991px) {

	.result-page-search,
	.center-list-grid {
		grid-template-columns: 300px 1fr !important;
	}

	.center-item {
		margin: 0;
	}

	.center-item-logo {
		width: 60px;
		height: 60px;
	}

	.center-list-nav-wrapper {
		max-height: 540px;
		height: auto;
	}

	.center-list-preview {
		max-height: unset;
	}
}

@media screen and (max-width: 767px) {

	.center-list-grid {
		display: block !important;
	}

	.center-list-preview {
		display: block;
		position: fixed;
		bottom: -1000px;
		left: 0;
		transition: all .5s ease-in;
	}

	.center-list-preview.active {
		bottom: 0;
		box-shadow: 0px -4px 7px -2px rgb(0 0 0 / 25%);
		height: auto;
		max-height: unset;
		z-index: 2;
	}

	.center-list-preview.active .center-data-preview {
		max-height: calc(100vh - 130px);
	}

	.result-page-search {
		margin: 15px 0 !important;
		grid-template-columns: 1fr !important;
	}

	.result-page-search .search-form-v2,
	.result-page-search .result-page-header {
		display: block;
		width: 100%;
	}

	.result-page-search .result-page-header {
		order: 2;
	}

	.search-form-v2 .search-filters {
		margin-top: 13px;
	}

	.center-item {
		padding: 15px 10px;
	}

	.center-list-grid {
		margin-top: 0;
	}

	body.no-scroll {
		overflow: hidden;
	}

	.center-list-preview-close-bar {
		display: flex;
	}

	.center-preview-services {
		display: block;
	}

	.iframe-map {
		height: 220px;
	}

	.center-data-preview.custom-scrollbar::-webkit-scrollbar-track {
		background-color: #e7e7e7;
	}

	.center-data-preview.custom-scrollbar::-webkit-scrollbar-thumb {
		background-color: #aaa;
	}

	.custom-scrollbar::-webkit-scrollbar {
		width: 5px;
	}

	.center-preview-services>p {
		margin-bottom: 10px;
	}

	.result-page-header>h1 {
		font-size: .9rem !important;
	}

	.center-data-basic-details>h2 {
		font-size: 1.2rem;
	}

	.search-found-dialog {
		display: none;
	}

	.center-list-nav-wrapper {
		max-height: unset;
		height: auto;
		min-height: unset;
	}

	.data-preview-overlay {
		position: fixed;
		top: 51px;
		left: 0;
		width: 100%;
		bottom: 0;
		filter: blur(10px);
		z-index: 0;
		display: block;
	}

	.center-data-preview-footer {
		text-align: center;
	}
}