@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;800&display=swap');

* {
	box-sizing: border-box;
	font-family: "Poppins", Arial, sans-serif;
}

:root {
	--color-theme: #ff6969;
	--color-warning: #e68040;
	--color-primary: #4983ff;
	--color-danger: #f87474;
	--color-lightgray: #f2f6fc;
	--color-midgray: #989898;
	--color-darkgray: #474747;
	--color-success: #0eb02b;
	--color-white: #fff;
	--font-light: 100;
	--font-normal: 300;
	--font-bold: 500;
	--font-bolder: 800;
	--font-size-md: 1.3rem;
	--font-size-lg: 1.8rem;
	--font-size-xlg: 2.3rem;
}

body {
	background-color: var(--color-lightgray);
	background-color: #e3ecfa;
	color: var(--color-darkgray);
	font-weight: var(--font-normal);
	margin: 0;
	padding: 50px 0 0 0;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
}

.container-fluid {
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

p {
	margin: 0 0 10px 0;
}

h1,
h2,
h3,
h4,
h5 {
	line-height: 1.2;
	margin: 0 0 12px 0;
}

input,
textarea,
select,
button {
	font-size: 15px;
	color: #474747;
	font-weight: 300;
}

.page-section {
	padding: 80px 0;
}

.switch {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 22px;
}

.btn,
button {
	padding: 6px 15px;
	border: 0 none;
	outline: none;
	cursor: pointer;
	min-height: 40px;
	display: inline-block;
	background-color: #dadada;
	display: inline-flex;
	align-items: center;
	transition: all .3s ease-in-out;
}

a.btn {
	color: var(--color-white) !important;
	color: #484848 !important;
	outline: none;
}

a.btn-theme,
.btn-theme,
.bg-theme {
	background-color: var(--color-theme);
	color: #fff !important;
}

a.btn-success,
.btn-success,
.bg-success {
	background-color: var(--color-success);
	color: #fff !important;
}

a.btn-warning,
.btn-warning,
.bg-warning {
	background-color: var(--color-warning);
	color: #fff !important;
}

a.btn-primary,
.btn-primary,
.bg-primary {
	background-color: var(--color-primary);
	color: #fff !important;
}

.color-theme>a:hover,
a:hover,
button:hover,
.btn:hover {
	filter: saturate(2)
}

.btn-theme-border,
a.btn-theme-border {
	border: 1px solid var(--color-theme);
	background-color: #fff;
	color: var(--color-theme);
	padding: 6px 15px;
}

.btn-theme-border:hover {
	background-color: var(--color-theme);
	color: #fff;
}

.btn-cta {
	background-color: #ffdf00;
}

.btn-sm {
	font-size: .8rem;
	padding: 4px 10px;
	min-height: 28px;
}

.btn-rounded {
	border-radius: 6px;
}

.input-control {
	display: block;
	margin: 0 0 20px 0;
	width: 100%;
}

.input-control:last-child {
	margin-bottom: 0;
}

.section-label,
.input-control>label {
	font-size: .8rem;
	margin: 0 0 5px 0;
	display: flex;
	align-items: baseline;
	text-transform: uppercase;
	color: #585858;
	width: 100%;
	position: relative;
}

.input-control>label>a,
.input-control>label>svg {
	margin-left: 5px;
}

input::placeholder {
	color: #bbb;
	font-weight: 300;
}

.section-label {
	color: var(--color-theme);
	margin-bottom: 10px;
}

/* a>svg {
	display: flex;
	align-items: center;
} */

select,
input,
textarea {
	border: 1px solid #c6c6c6;
	width: 100%;
	height: 36px;
	border-radius: 0;
	padding: 0 8px;
	outline: none;
	font-family: "Poppins", Arial, sans-serif;
	font-weight: 400;
	color: #3e3e3e;

}

input:focus,
textarea:focus {
	border-color: #5c5c5c;
}

input[type="file"] {
	padding: 5px 8px;
}

input[type="password"] {
	letter-spacing: 4px;
}

button>h2 {
	padding: 4px 15px !important;
	font-weight: 300;
}

.color-theme {
	color: var(--color-theme);
}

.icon-right {
	margin-left: 4px;
}

.log-in .dash-wrapper {
	margin: 40px auto;
}

.sign-up .dash-wrapper {
	margin: 40px auto;
}

.panel {
	background-color: var(--color-white);
	border-radius: 4px;
	margin-bottom: 20px;
}

.panel.border-radius-lg {
	border-radius: 12px;
}

.panel.box-shadow {
	box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.2);
}

.panel-header {
	color: var(--color-theme);
	font-weight: 400;
	padding: 15px;
	font-size: 1.1rem;
}

.panel-header.collapse {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.panel-header.collapse>.btn-collapse {
	font-size: 1.8rem;
	padding: 3px 5px;
	border-radius: 4px;
	cursor: pointer;
}

.panel-header.collapse>.btn-collapse:hover,
.panel-header.collapse>.btn-collapse.active {
	background-color: #f8f8f8;
	border: 1px solid #f1f1f1;
}

.panel-body.collapse.active,
.panel-body {
	padding: 15px;
	transition: all .5s ease-in-out;
}


.panel-body.collapse {
	height: 0;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
}

.panel-body.collapse.active {
	height: auto;
	overflow: unset;
}

.panel-footer {
	padding: 15px;
}

.panel:last-child {
	margin-bottom: 0;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-uppercase {
	text-transform: uppercase;
}

.dash-wrapper-fluid {
	margin: 30px 0 0 0;
	width: 100%;
}

.dash-wrapper {
	max-width: 400px;
	margin: 30px auto 0 auto;
}

.dash-wrapper-compact {
	max-width: 800px;
	margin: 30px auto 0 auto;
}

.grid-two {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px;
	margin-bottom: 15px;
}

.grid-two:last-child {
	margin-bottom: 0;
}

.tooltip {
	max-width: 250px;
}

.elem-block {
	display: block;
	width: 100%;
}

.input-control-flex {
	display: flex;
}

.search-form-v2 input {
	height: 40px;
	border-right: 0 none;
	border-color: var(--color-theme);
}

.font-normal {
	font-weight: 400;
}

.ellipsis-3,
.ellipsis-2,
.ellipsis-1 {
	display: -webkit-box;
	line-height: 22px;
	max-height: 22px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ellipsis-1 {
	max-height: 37px;
}

.ellipsis-2 {
	max-height: 45px;
	-webkit-line-clamp: 2;
}

.ellipsis-3 {
	max-height: 65px;
	margin-bottom: 5px;
	-webkit-line-clamp: 3;
}

.loading.medium,
.loading {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -60px;
}

.loading.small {
	height: auto;
	display: block;
	text-align: center;
	margin: 30px auto;
}

.loading.medium {
	height: calc(100vh - 290px);
	margin: 0 !important;
}

.loading.medium>.pl {
	width: 3.5em;
	height: 3.5em;
}

.loading.small>.pl {
	width: 2.5em;
	height: 2.5em;
}

.pl {
	width: 6em;
	height: 6em;
}

.pl.small {
	width: 3em;
	height: 3em;
}

.pl__ring {
	animation: ringA 2s linear infinite;
}

.pl__ring--a {
	stroke: #f42f25;
}

.pl__ring--b {
	animation-name: ringB;
	stroke: #f49725;
}

.pl__ring--c {
	animation-name: ringC;
	stroke: #255ff4;
}

.pl__ring--d {
	animation-name: ringD;
	stroke: #f42582;
}

.alert {
	background-color: #00ff2e3b;
	border: 1px solid #60e477;
	border-radius: 4px;
	box-shadow: 0 2px 9px 4px rgb(0 0 0 / 8%);
	color: #222;
	cursor: pointer;
	display: none;
	max-width: 280px;
	padding: 12px;
	position: fixed;
	right: 20px;
	top: 80px;
	z-index: 99;
}

.alert.active {
	display: inline;
}

.alert.hidden {
	display: none;
}

.alert svg {
	font-size: 1.7rem;
	color: #fff;
}

.alert-wrapper {
	align-items: center;
	color: #fff;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 2rem 1fr;
	justify-content: space-between;
}

.alert.success {
	background-color: #2dab44;
	border-color: #1e8e32;
}

.alert.danger {
	background-color: #e34f4f;
	border-color: #de7d7d;
}

.alert.warning {
	background-color: #da8313;
	border-color: #d37941;
}

.cta-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.cta-wrapper h2 {
	font-size: 2rem;
}

.text-strikethrough {
	margin: 40px auto;
	position: relative;
	max-width: 260px;
}

.text-strikethrough hr {
	margin: 0 auto;
	border-top: 1px solid #cdcdcd;
	border-bottom: 1px solid #fcfcfc;
}

.text-strikethrough span {
	position: absolute;
	left: 50%;
	top: -8px;
	font-size: .8rem;
	background-color: #e4ecfb;
	padding: 0 8px;
	transform: translateX(-50%);
	color: #ababab;
	text-transform: uppercase;
}

.float-bar .btn,
.page-title .btn {
	font-size: .8rem;
	background-color: #fff;
	color: var(--color-theme) !important;
	padding: 8px;
	min-height: auto;
}

.nav-back-btn {
	min-height: auto;
	padding: 10px;
	position: absolute;
	top: -23px;
	z-index: 1;
	background-color: #fff;
	border-radius: 100px;
	font-size: 1rem;
	left: -1px;
	transition: none !important;
	display: flex;
	gap: 8px;
	align-items: center;
}

.centers-history {
	background-color: #fff;
	margin-top: 40px;

}

.centers-history-grid {
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 15px;
	flex-wrap: wrap;
}

.centers-history-grid>a {
	width: 32%;
}

.centers-history-grid>a>.panel {
	border: 1px solid #eaeaea;
}

.centers-history-grid>a:hover>.panel {
	border: 1px solid #c7c7c7;
}

.center-history-item .iframe-map {
	width: 100%;
	height: 200px;
}

.align-center {
	text-align: center;
}

/* Animations */
@keyframes ringA {

	from,
	4% {
		stroke-dasharray: 0 660;
		stroke-width: 20;
		stroke-dashoffset: -330;
	}

	12% {
		stroke-dasharray: 60 600;
		stroke-width: 30;
		stroke-dashoffset: -335;
	}

	32% {
		stroke-dasharray: 60 600;
		stroke-width: 30;
		stroke-dashoffset: -595;
	}

	40%,
	54% {
		stroke-dasharray: 0 660;
		stroke-width: 20;
		stroke-dashoffset: -660;
	}

	62% {
		stroke-dasharray: 60 600;
		stroke-width: 30;
		stroke-dashoffset: -665;
	}

	82% {
		stroke-dasharray: 60 600;
		stroke-width: 30;
		stroke-dashoffset: -925;
	}

	90%,
	to {
		stroke-dasharray: 0 660;
		stroke-width: 20;
		stroke-dashoffset: -990;
	}
}

@keyframes ringB {

	from,
	12% {
		stroke-dasharray: 0 220;
		stroke-width: 20;
		stroke-dashoffset: -110;
	}

	20% {
		stroke-dasharray: 20 200;
		stroke-width: 30;
		stroke-dashoffset: -115;
	}

	40% {
		stroke-dasharray: 20 200;
		stroke-width: 30;
		stroke-dashoffset: -195;
	}

	48%,
	62% {
		stroke-dasharray: 0 220;
		stroke-width: 20;
		stroke-dashoffset: -220;
	}

	70% {
		stroke-dasharray: 20 200;
		stroke-width: 30;
		stroke-dashoffset: -225;
	}

	90% {
		stroke-dasharray: 20 200;
		stroke-width: 30;
		stroke-dashoffset: -305;
	}

	98%,
	to {
		stroke-dasharray: 0 220;
		stroke-width: 20;
		stroke-dashoffset: -330;
	}
}

@keyframes ringC {
	from {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: 0;
	}

	8% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -5;
	}

	28% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -175;
	}

	36%,
	58% {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: -220;
	}

	66% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -225;
	}

	86% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -395;
	}

	94%,
	to {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: -440;
	}
}

@keyframes ringD {

	from,
	8% {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: 0;
	}

	16% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -5;
	}

	36% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -175;
	}

	44%,
	50% {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: -220;
	}

	58% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -225;
	}

	78% {
		stroke-dasharray: 40 400;
		stroke-width: 30;
		stroke-dashoffset: -395;
	}

	86%,
	to {
		stroke-dasharray: 0 440;
		stroke-width: 20;
		stroke-dashoffset: -440;
	}
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #1bdd50;
}

input:focus+.slider {
	box-shadow: 0 0 1px #1bdd50;
}

input:checked+.slider:before {
	-webkit-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.nogaps {
	margin: 0;
	padding: 0;
}