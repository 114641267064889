.sponsored {
	margin-bottom: 40px;
}

.sponsored-header {
	margin-bottom: 15px;
}

.sponsored-list {
	display: flex;
	overflow-x: auto;
	gap: 15px;
	white-space: nowrap;
	padding-bottom: 15px;
}

.sponsored-list.custom-scrollbar::-webkit-scrollbar {
	height: 7px;
}

.sponsored-item {
	flex: 0 0 auto;
	display: inline-block;
	background-color: #fff;
	width: 200px;
	border-radius: 12px;
	border: 1px solid transparent;
}

.sponsored-item:hover {
	border-color: #f8e048;
}

.sponsored-item-header {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 200px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.sponsored-item-body {
	padding: 10px;
}

.product-title {
	font-size: 14px;
	white-space: normal;
	text-transform: capitalize;
}

.sponsored-item-footer {
	text-align: center;
	padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
	.sponsored-header {
		padding: 0 15px;
	}
}