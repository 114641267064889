.single-center .center-page-wrapper {
	max-width: 560px;
	margin: 20px auto;
}

.page-header {
	background-color: #fff;
	border-radius: 12px;
	margin-bottom: 15px;
}

.page-cover {
	display: block;
	max-height: 200px;
	object-fit: cover;
	width: 100%;
	border-radius: 12px;
	z-index: 0;
	position: relative;
}

.page-name-wrapper {
	border: 10px solid rgb(255 255 255 / 20%);
	border-bottom: 0 none;
	position: relative;
	width: 90%;
	margin: -60px auto 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	text-align: center;
}

.page-name {
	border-radius: 4px;
	padding: 15px;
	background-color: #fff;

}

.page-name>h1 {
	font-size: 1.6rem;
	margin: 0 0 10px 0;
}

.page-handle {
	font-size: .9rem;
	color: var(--color-theme);
}

.page-controls-wrapper {
	background-color: #fff;
	border-radius: 12px;
	padding: 10px;
	margin-bottom: 15px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-content: space-between;
	text-align: center;
	grid-gap: 10px;
}

.page-controls-wrapper>a {
	border-radius: 12px;
	padding: 8px 0;
	background-color: #eee;
	font-weight: 400;
}

.page-controls-wrapper>a.active.active:hover,
.page-controls-wrapper>a.active.active {
	background-color: var(--color-theme);
	color: #fff !important;
}

.page-controls-wrapper>a.disable.active {
	background-color: transparent;
	color: #333 !important;
}

.page-controls-wrapper>a.disable.active:hover {
	background-color: #f0f0f0;
	color: var(--color-theme) !important;
}

.page-content>iframe,
.page-content {
	border-radius: 12px;
}

.page-content {
	margin-bottom: 10px;
}

.page-content.white {
	background-color: #fff;
	padding: 10px;
}

.page-content.shadow {
	box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 13%);
}

.text-icon-flex {
	align-items: start;
	display: grid;
	justify-content: start;
	grid-gap: 10px;
	grid-template-columns: auto 1fr;
	margin-bottom: 15px;
	width: 100%;
}

.text-icon-flex:last-child {
	margin-bottom: 0;
}

.text-icon-flex>svg {
	font-size: 1.5rem;
	color: #807cea;
}

.page-control-parent {
	position: fixed;
	top: -100px;
	transition: all .3s ease-in-out;
}


.page-control-parent.float-me {
	top: 50px;
	background-color: #fff;
	width: 100%;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	z-index: 1;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15);
	left: 0;
}

.page-control-parent.float-me~.page-content-wrapper {
	margin-top: 15px;
}

.page-control-parent.float-me>.page-control-parent-basic {
	text-align: center;
	padding: 10px;
	margin: 0;
	background-image: url("../../assets/images/misc/float-center-bar.jpg");
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.page-control-parent.float-me .page-controls-wrapper {
	margin-bottom: 0;
}

.page-control-inner {
	background-color: #ffffff61;
	max-width: 1080px;
	margin: 0 auto;
	padding: 8px 12px;
	border-radius: 12px;
	display: inline-block;
}

.page-control-parent.float-me>.page-control-parent-basic>div>h2 {
	font-size: 1.2rem;
	margin-bottom: 2px;
	color: #222;
}

.share-action-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 15px;
}

.share-action-item>small {
	margin-bottom: 5px;
}

.blog-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.blog-author-name {
	margin: 0;
	color: #333;
}

.blog-body {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
}

.blog-content-image {
	width: 100%;
	margin-bottom: 10px;
	border-radius: 12px;
}

.blog-content-body {
	padding-left: 15px;
	padding-right: 15px;
}

.blog-content-separator {
	width: 80px;
	margin: 20px auto;
	border-bottom: 2px solid #eee;
}

.blog-content-title {
	font-size: 1.1rem;
}

.blog-content-title:hover,
.blog-read-more {
	color: var(--color-theme) !important;
}

.blog-author-image {
	width: 25px;
}

.blog-author-block,
.blog-author-date-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.blog-author-block {
	gap: 8px;
}

.blog-content-excerpt {
	font-size: .9rem;
	margin-bottom: 0;
}

.blog-footer {
	background-color: #d9dee8;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
}

.blog-comment-item {
	background-color: #fff;
	padding: 10px;
	border-radius: 8px;
	margin-bottom: 10px;
}

.blog-comment-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
}

.blog-comment-image {
	width: 25px;
	max-height: 25px;
	object-fit: cover;
}

.blog-comment-display-name {
	white-space: nowrap;
}

.blog-comment-content {
	padding-left: 10px;
	border-left: 3px solid #dedede;
	margin-top: 7px;
	margin-left: 30px;
}

.blog-comment-content>small {
	font-size: .9rem;
}

@media screen and (max-width: 767px) {
	.single-center .center-page-wrapper {
		padding: 0;
		margin: 0 auto 20px auto;
	}

	.page-cover {
		height: 120px;
	}

	.page-cover,
	.center-page-wrapper .page-header {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.page-name>h1 {
		font-size: 1rem;
		margin-bottom: 5px;
	}

	.page-handle {
		font-size: .8rem;
	}

	.page-controls-wrapper>a {
		font-size: .9rem;
		padding: 5px 0;
	}

	.page-control-parent.float-me {
		left: 0;
		width: 100%;
		top: 0;
		z-index: 99;
		animation: slideInFromTop 0.3s ease-out;
	}

	@keyframes slideInFromTop {
		0% {
			transform: translateY(-100%);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.page-control-inner {
		padding: 8px;
	}

	.page-control-parent.float-me .page-controls-wrapper,
	.page-control-parent.float-me>.page-control-parent-basic {
		padding: 10px;
	}

	.page-control-parent.float-me>.page-control-parent-basic>div>h2 {
		font-size: 1rem;
		margin-bottom: 2px;
	}

	.page-control-parent.float-me .page-controls-wrapper>a,
	.page-control-parent.float-me>.page-control-parent-basic>div>.page-handle {
		font-size: .8rem;
	}

	.page-control-parent.float-me .nav-back-btn {
		position: fixed;
		top: 6px;
		left: 8px;
		height: auto;
		padding: 8px;
		line-height: 0;
	}

	.page-control-parent.float-me .nav-back-btn {
		font-size: 1.1rem;
		padding: 7px;
		left: 8px;
		top: 10px;
	}

	.blog-author-date-block {
		display: block;
		text-align: center;
	}

	.blog-author-block {
		justify-content: center;
		margin-bottom: 10px;
	}
}