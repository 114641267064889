.result-page-search {
	display: grid;
	grid-template-columns: 400px 1fr;
	margin: 15px 0 25px 0;
	grid-gap: 15px;
	align-items: start;
	justify-content: space-between;
}

.search-filters {
	display: flex;
	gap: 20px;
}

.search-filter-label {
	font-weight: 600;
	margin-right: 8px;
}

.search-form-v2>.input-control-flex>select {
	width: auto;
	height: 40px;
	border-top: 1px solid var(--color-theme);
	border-right: 0 none;
	border-bottom: 1px solid var(--color-theme);
	border-left: 1px solid var(--color-theme);
}

@media screen and (max-width: 767px) {

	.center-list-nav-wrapper.active,
	.result-page-search.active {
		filter: blur(5px);
	}

	.center-list-nav-wrapper.active {
		z-index: -1;
	}

	.center-list-parent {
		min-height: calc(100vh - 295px);
	}
}