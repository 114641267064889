.footer {
	background-color: #333;
	color: #fff;
	padding: 50px 0;
	background-image: url(../../assets/images/misc/logo.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: -140px 0px;
}

.footer-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: start;
	justify-content: space-between;
}

.footer h3 {
	margin-top: 0;
}

.footer ul {
	padding: 0;
	margin: 0;
}

.footer ul li {
	list-style: none;
	margin-bottom: 5px;
}

.footer ul li a {
	color: #fff;
	text-decoration: none;
}

.footer ul li a:hover {
	color: var(--color-theme);
}

@media screen and (max-width: 767px) {
	.footer-grid {
		display: block;
	}

	.footer-grid>div {
		margin-bottom: 30px;
	}

	.footer-grid>div:last-child {
		margin-bottom: 0;
	}
}