.hero {
	background-image: url(../../assets/images/misc/hero-bg-blur.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
}

.hero-label-content {
	color: var(--color-white);
	font-size: 2.6rem;
	margin: 0;
}

.hero-label-filter {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	margin-bottom: 5px;
}

.search-form {
	border: 10px solid rgb(255 255 255 / 9%);
	border-radius: 100px;
	max-width: 560px;
	margin: 0 auto;
}

.filter-category-parent,
.search-form-wrapper {
	display: flex;
	align-items: center;
	gap: 15px;
	width: 100%;
}

.filter-category-item {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.filter-category-item>p {
	color: var(--color-white);
	font-size: 1.2rem;
	font-weight: 500;
	margin: 0;
}

.search-form-wrapper {
	background-color: #fff;
	padding: 12px 12px 12px 40px;
	border-radius: 100px;
	justify-content: space-between;
	gap: 0;
}

.search-form-item {
	position: relative;
	max-width: 100%;
}

.search-form-item.wider {
	max-width: 500px;
}

.search-form-item.keyword-item {
	padding-left: 0;
	min-width: 43%;
}

.search-form-item:last-child,
.search-form-item.filter-item {
	border-right: 0 none;
}

.search-form-item.filter-item {
	border-left: 1px solid #ece7e7;
	margin-left: 20px;
	padding-left: 20px;
	min-width: 42%;
}

.search-form-item.submit-item {
	padding-right: 0;
	width: 20%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.search-form-item.filter-item.hidden {
	display: none;
}

.search-form-item p {
	color: #696969;
	text-shadow: none;
	font-weight: bold;
	margin-bottom: 0;
}

.search-type {
	display: flex;
	gap: 20px;
}

.search-type p {
	color: #bbb;
	cursor: pointer;
}

.search-type p:hover,
.filter-item p,
.search-type p.active {
	color: #445fff;
}

.search-form-item>input {
	border: 0 none;
	font-size: 1.1rem;
	outline: none;
	text-transform: capitalize;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
}

/* .search-form-item.filter-item>input {
	text-overflow: ellipsis;
	white-space: nowrap;
} */

.search-form-item>input::placeholder {
	color: #aaa;
}

.search-form-item>input:disabled {
	background-color: transparent;
}

.search-form-item.submit-item>button {
	height: 50px;
	width: 50px;
	border-radius: 100px;
	outline: none;
	border: 0 none;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-white);
	background-color: #ff6969;
}

.info-grid {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	align-items: center;
	justify-content: start;
}

.info-image {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-top: 80%;
	width: 50%;
}

.info-grid.community .info-image {
	background-image: url(../../assets/images/misc/community-2.jpg);
	width: 100%;
	height: 100%;
}

.info-context {
	background-color: #c0e4ff;
	padding: 30px;
	box-shadow: 0 1px 22px 0px rgb(0 0 0 / 17%);
}

.info-grid.community-2 .info-image {
	background-image: url(../../assets/images/misc/community.jpg);
	width: 100%;
	height: 100%;
}

.info-context.right {
	margin-left: -50px;
	margin-right: 0 !important;
	z-index: 1;
}

.info-context.left {
	margin-right: -50px;
	margin-left: 0 !important;
	z-index: 1;
}

.info-header {
	margin-bottom: 30px;
}

.featured {
	background-color: #fff;
	background-image: url(../../assets/images/misc/shapes-bg.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.featured-grid {
	display: grid;
	grid-template-columns: 400px 1fr;
	align-items: center;
	justify-content: space-between;
	grid-gap: 50px;
}

.featured-bg {
	background-image: url(../../assets/images/misc/featured.png);
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	height: 580px;
}

.testi {
	background-color: #fff;
}

.testi-header {
	margin-bottom: 30px;
}

.testi-list-wrapper {
	display: grid;
	align-items: start;
	justify-content: space-between;
	grid-gap: 20px;
	grid-template-columns: repeat(2, 1fr);
}

.testi-item {
	border: 1px solid #e5edf5;
	padding: 20px;
}

.testi-head>svg {
	font-size: 2rem;
	color: #50adfa;
}

.testi-head,
.testi-body {
	margin-bottom: 20px;
}

.testi-footer {
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.testi-footer>img {
	width: 40px;
	height: 40px;
	object-fit: cover;
}

.testi-footer .testi-name {
	font-weight: 400;
	display: block;
	margin-bottom: 0;
	line-height: 1;
}

.cta {
	background-color: #a6d2ff;
	background-image: url("../../assets/images/misc/cta.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.center-counter {
	background-color: #fff;
}

.top-cities-wrapper {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 20px;
	justify-content: center;
	align-items: start;
	margin-top: 40px;
	text-align: center;
}

.top-city-item {
	margin-bottom: 50px;
	text-align: center;
}

.top-city-header {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	width: 180px;
	height: 180px;
	margin: 0 auto 15px auto;
}

.top-city-body>h4 {
	font-size: 1.4rem;
}

.centers-categories {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	flex-wrap: wrap;
}

.centers-categories .capsule {
	font-size: .8rem;
	background-color: #b1b1b1;
	color: #fff;
	padding: 2px 10px;
	border-radius: 12px;
}

@media screen and (max-width: 991px) {
	.top-city-item {
		margin-bottom: 30px;
		width: 300px;
	}

	.top-cities-wrapper {
		display: flex;
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 767px) {
	.hero-label-parent {
		width: 90%;
	}

	.featured-grid {
		display: block;
		margin-bottom: 15px;
	}

	.featured-bg {
		margin: 30px 0;
		background-position: top center;
		height: auto;
		padding-top: 80%;
	}

	.featured-header {
		text-align: left;
	}

	.filter-category-parent {
		overflow-y: hidden;
		overflow-x: auto;
		justify-content: flex-start;
		padding-bottom: 10px;
	}

	.hero {
		height: auto;
		padding: 50px 0;
	}

	.search-form {
		border-radius: 0;
		max-width: 100%;
	}

	.search-form-wrapper {
		border-radius: 0 !important;
		display: block !important;
		padding: 15px;
	}

	.search-form-item {
		max-width: 100%;
	}

	.search-form-item.wider {
		padding: 0;
		border: 0 none;
		width: 100%;
		max-width: unset;
	}

	.search-form-item.filter-item {
		margin: 20px 0;
		padding-left: 0;
		border: 0 none;
	}

	.search-form-item.submit-item {
		width: 100%;
		display: block;
	}

	.search-form-item.submit-item>button {
		border-radius: 0;
		width: 100%;
	}

	.search-form-item input.location {
		border-bottom: 3px solid #d6d6d6;
		padding: 8px 0;
		transition: all .3s ease-in-out;
	}

	.search-form-item input.location:focus {
		border-bottom: 3px solid #727272 !important;
	}

	.info-grid {
		display: block;
		margin-bottom: 25px;
		width: 100%;
		display: block;
	}

	.info-context.right {
		margin-left: 0;
	}

	.info-context.left {
		margin-right: 0;
	}

	.info-context {
		box-shadow: none;
		display: block;
		padding: 20px;
		width: 100%;
	}

	.info-grid.community-2 {
		display: grid;
		grid-template-columns: 1fr;
	}

	.info-context.left {
		order: 2;
	}

	.info-grid:last-child {
		margin-bottom: 0;
	}

	.testi-list-wrapper {
		display: block;
	}

	.testi-item {
		margin-bottom: 20px;
	}

	.cta {
		padding: 40px 0;
	}

	.cta>.container>div {
		text-align: center;
	}

	.cta a h3 svg {
		font-size: 2rem;
	}

	.search-form-wrapper .search-form-item.keyword-item>input {
		border-bottom: 2px solid #ededed;
		transition: border-color .3s ease-in-out;
	}

	.search-form-wrapper .search-form-item.keyword-item>input:focus {
		border-color: #ccc;
	}

	.hero-label-filter {
		display: block;
		justify-content: flex-start;
	}

	.search-form {
		border: 0 none;
		margin-top: 10px;
	}

	.top-cities-wrapper {
		display: block;
	}

	.top-city-item {
		margin-bottom: 50px;
		width: 100%;
		text-align: center;
	}

	.top-city-header {
		width: 150px;
		height: 150px;
		margin-bottom: 15px;
		margin: 0 auto 15px auto;
	}

	.top-city-item:last-child {
		margin-bottom: 50px;
	}
}

@media screen and (max-width: 490px) {
	.hero-label-filter {
		margin-bottom: 0;
	}

	.hero-label-content {
		font-size: 2rem;
	}

	.filter-category-parent {
		justify-content: start;
	}

	.filter-category-item>p {
		font-size: 1rem;
	}
}