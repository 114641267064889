.visible-sm,
.visible-xs {
	display: none;
}

@media screen and (min-width: 992px) {

	.hidden-lg,
	.visible-md,
	.visible-sm,
	.visible-xs {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	.hidden-md {
		display: none;
	}

	.visible-md {
		display: initial;
	}
}

@media screen and (max-width: 767px) {
	.page-section {
		padding: 30px 0;
	}

	.hidden-sm {
		display: none;
	}

	.visible-sm {
		display: initial;
	}

	.grid-two {
		display: block;
	}

	.grid-two>.grid-item {
		margin-bottom: 15px;
	}

	.grid-two>.grid-item:last-child {
		margin-bottom: 0;
	}

	.cta-wrapper {
		justify-content: center;
	}

	.centers-history-grid>a {
		width: 100%;
	}

	.nav-back-btn {
		top: -21px;
	}

	.nav-back-btn>span {
		font-size: .9rem;
	}
}

@media screen and (max-width: 414px) {
	body {
		padding: 50px 0 0 0;
	}

	.hidden-xs {
		display: none;
	}

	.visible-xs {
		display: initial;
	}

	.cta-wrapper h2 {
		font-size: 1.6rem;
	}

	.nav-back-btn {
		font-size: 1.2rem;
		padding: 7px;
		left: -8px;
		top: -18px;
	}

	.alert {
		top: 10px;
		right: 10px;
	}

	.float-bar .btn,
	.page-title .btn {
		border-radius: 100px;
	}
}