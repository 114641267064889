.main-nav {
	background-color: var(--color-white);
	/* box-shadow: 0 1px 4px 0px #d4dcea; */
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	transition: all .3s ease-in-out;
}

.nav-grid {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.logo-wrapper>a {
	display: flex;
	align-items: center;
	gap: 8px;
}

.logo-wrapper .nav-logo {
	transition: all .3s ease-in-out;
	width: 30px;
}

.logo-wrapper .app-name {
	font-size: 1.1rem;
	font-weight: 400;
}

.nav-avatar-link {
	align-items: center;
	display: flex !important;
	gap: 10px;
}

.nav-avatar {
	font-size: 1.4rem;
}

.main-nav ul {
	align-items: center;
	display: flex;
	justify-content: center;
	gap: 15px;
	margin: 0;
	padding: 0;
}

.main-nav ul>li {
	list-style: none;
}

.main-nav ul>li>a {
	display: block;
	border-bottom: 4px solid transparent;
	padding: 13px 0 9px 0;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: all .2s ease;
	font-weight: 400;
}

.main-nav ul>li>a.active {
	border-color: var(--color-theme);
	color: var(--color-theme);
}

.nav-left {
	position: relative;
}

.nav-left,
.nav-logo {
	display: flex;
	justify-content: flex-start;
}

.nav-center ul {
	gap: 25px;
}

.nav-center ul>li>a {
	font-weight: bold;
}

.nav-right {
	display: flex;
	justify-content: flex-end;
}

.nav-right ul {
	gap: 25px;
}

.nav-separator {
	border-right: 1px solid #cbcbcb;
	height: 20px;
}

.nav-avatar-link {
	color: #333 !important;
}

.nav-avatar {
	color: var(--color-theme);
}

.side-nav-section {
	height: calc(100vh - 59px);
	overflow-y: auto;
	z-index: 1;
}

button.side-nav-link {
	background-color: inherit;
}

.side-nav-link,
.side-nav-section,
.side-nav-sticky {
	display: block;
	width: 200px;
}

.side-nav-sticky {
	background-color: var(--color-white);
	position: fixed;
	top: 58px;
	height: inherit;
}

.side-nav-link {
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 15px;
	text-decoration: none;
	color: #4f4f4f;
	font-size: 1.2rem;
}

.side-nav-active {
	height: 28px;
	width: 5px;
	background-color: transparent;
	display: block;
	transition: all .3s ease-in-out;
}

button.side-nav-link:hover,
.side-nav-link:hover .side-nav-active,
.side-nav-link.active {
	color: var(--color-theme);
}

.side-nav-link.active .side-nav-active {
	background-color: var(--color-theme);
}



@media screen and (max-width: 767px) {
	.nav-action-icon {
		padding: 16.5px 0 !important;
	}

	.nav-action-icon>svg {
		display: flex;
		font-size: 1.4rem;
	}

	.side-nav-link,
	.side-nav-section,
	.side-nav-sticky {
		width: 44px;
	}


	.side-nav-link {
		padding: 8px 6px 8px 0;
		gap: 6px;
	}

	.side-nav-active {
		width: 3px;
	}

	.side-nav-link>svg {
		font-size: 1.4rem;
	}

	.main-nav ul>li>a,
	.logo-wrapper .app-name {
		font-size: .9rem;
	}

	.main-nav ul>li>a {
		padding: 15px 0 11px 0;
	}
}

@media screen and (max-width: 414px) {

	.nav-right ul {
		gap: 15px;
	}

	.nav-action-icon {
		padding: 7px 0 !important;
	}

	.logo-wrapper .nav-logo {
		width: 25px;
	}

	.side-nav-sticky {
		top: 39px;
	}

	.side-nav-section {
		height: calc(100vh - 40px);
	}
}