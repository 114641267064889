.post-hero {
	padding: 60px 0;
	background-image: url("../../assets/images/misc/float-center-bar.jpg");
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}

.post-hero h1 {
	margin: 0;
	padding: 0;
	text-align: center;
}

.post-content-wrapper {
	margin: 50px 0;
}

.post-aside {
	display: grid;
	grid-template-columns: 1fr 300px;
	grid-gap: 20px;
	align-items: start;
	justify-content: space-between;
}

@media screen and (max-width: 767px) {
	.post-aside {
		display: block;
	}
}